var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.message_type
    ? _c(
        "section",
        { staticClass: "clearfix" },
        [
          _c("SearchRecipients", {
            key: "forceRedrawRecipientsKey_" + _vm.forceRedrawRecipientsIndex,
            attrs: {
              message_type: _vm.message_type,
              user_uuid: _vm.user_uuid,
              functionbox_uuid: _vm.functionbox_uuid,
              isNumber: _vm.isNumber,
              singleSelect: _vm.singleSelect,
              participants: _vm.participants,
            },
            on: {
              IsSearching: _vm.IsSearching,
              IsTyping: _vm.IsTyping,
              forceRedraw: function ($event) {
                _vm.forceRedrawRecipientsIndex++
              },
            },
            model: {
              value: _vm.addresses,
              callback: function ($$v) {
                _vm.addresses = $$v
              },
              expression: "addresses",
            },
          }),
          _vm.addresses.length != 0
            ? _c(
                "b-button",
                {
                  staticClass: "mt-1 float-right",
                  attrs: { size: "sm", variant: "secondary" },
                  on: { click: _vm.ClearParticipants },
                },
                [
                  _c("i", { staticClass: "fal fa-trash-alt" }),
                  _vm._v("\n    " + _vm._s(_vm.$t("CLEAR")) + "\n  "),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }